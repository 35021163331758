@-webkit-keyframes animation-bouton-qui-remue {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 5px;
  }
}
@-moz-keyframes animation-bouton-qui-remue {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 5px;
  }
}
@-o-keyframes animation-bouton-qui-remue {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 5px;
  }
}
@keyframes animation-bouton-qui-remue {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 5px;
  }
}

.bouton-qui-remue {
  position: relative;
  margin-right: 10px;
  -webkit-animation: animation-bouton-qui-remue 1s 5;
  -moz-animation: animation-bouton-qui-remue 1s 5;
  -o-animation: animation-bouton-qui-remue 1s 5;
  animation: animation-bouton-qui-remue 1s 5;
}

.tooltip-details-anomalies {
  box-shadow: 0 0 4px 0 grey;
  background: white;
  opacity: 1;
  max-width: 450px;
  text-align: initial;
}

.tooltip-details-anomalies ul {
  margin: 0;
  font-weight: bold;
  padding-left: 10px;
}
