.calculateur {
  display: flex;
  padding: 0 10px;
}

.calculateur .calculateur-simulation {
  flex-grow: 1;
}

.calculateur .calculateur-instantanes {
  width: 200px;
}

.calculateur .actions-en-un-clic {
  display: flex;
}

.calculateur .actions-en-un-clic > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.calculateur .actions-en-un-clic h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.calculateur .paliers-souhaites-et-conditions {
  display: flex;
  margin-bottom: 20px;
}

.calculateur .paliers-souhaites-et-conditions .conditions {
  margin-left: 3rem;
}

.calculateur .parametres-generaux {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.calculateur .parametres-generaux .select-marque-itl-active {
  width: 45%;
  max-width: 300px;
  margin-right: 2rem;
}
