.badge-warning {
  border: 2px solid orange;
  padding: 15px 10px 15px 60px;
  border-radius: 5px;
  background: rgba(255, 162, 0, 0.2) url(picto/warning.svg) no-repeat 10px
    center;
  background-size: 36px;
  display: flex;
  align-items: center;
}
