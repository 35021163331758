.carte {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.carte > header {
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
  font-weight: 700;
  text-align: center;
}

.carte > header > div {
  padding: 0.5rem;
}

.carte .carte-contenu {
  padding: 1rem 1.2rem;
  display: flex;
  flex: 1;
}
