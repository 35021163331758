.chaine-de-loyers #paliers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #0073bd;
}

.chaine-de-loyers .chiffres {
  display: flex;
  font-size: 1.85rem;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: right;
}

.chaine-de-loyers .chiffres div:nth-child(2) span {
  font-size: 1.54rem;
  padding-left: 10px;
  padding-right: 10px;
}
