.maintenance h3 {
  margin-bottom: 0.3rem !important;
}

.maintenance input {
  margin-left: 1rem;
}

.maintenance input[type="checkbox"] {
  margin-left: 0;
  margin-right: 0.2rem;
}

.maintenance-champs {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.maintenance-champs .input-addon-append-item {
  text-transform: lowercase;
}

.maintenance-champs .infobulle-maintenance {
  margin-left: 0.5rem;
}

.warning-maintenance {
  margin-top: 1rem;
}
