.paliers-souhaites {
  width: 350px;
}

.paliers-souhaites h3 {
  margin-bottom: 0.5rem;
}

.paliers-souhaites .boutons-paliers {
  width: 212px;
  display: flex;
  justify-content: space-between;
}
