.derogation-de-teg {
  padding: 0 1rem;
}

.derogation-de-teg .le-commentaire {
  margin-top: 1rem;
}

.derogation-de-teg .les-boutons {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.derogation-de-teg .les-boutons button {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
