@media only screen and (max-width: 800px) {
  .produits-liste {
    width: 100%;
  }

  .un-produit {
    width: 100%;
    margin-right: 0;
  }

  .un-produit .assurance-france .souscription {
    height: auto;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .un-produit .assurance-france .souscription .titre {
    max-width: unset;
  }

  .un-produit .assurance-france .souscription .pure-button-group {
    margin-top: 0.2rem;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: stretch;
  }

  .un-produit .assurance-france .souscription button {
    flex: 1;
  }
}
