.assurance-espagne {
  margin-left: 3rem;
}

.assurance-espagne .main {
  display: flex;
  flex-direction: row;
}

.assurance-espagne .titre {
  line-height: 1.3rem;
  margin-right: 1rem;
}

.assurance-espagne .titre h3 {
  margin: 0;
}

.assurance-espagne-saisie {
  margin-left: 2rem;
  width: 210px;
  display: flex;
  flex-direction: column;
}

.assurance-espagne-saisie .une-prime {
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.assurance-espagne-saisie input {
  margin-left: 0.6rem;
}

.assurance-espagne .aide-a-la-saisie {
  display: flex;
}

.assurance-espagne .aide-a-la-saisie button {
  margin-left: 1rem;
}

.assurance-espagne .badge-derogation {
  display: flex;
  column-gap: 1rem;
  margin-top: 0.5rem;
}

.assurance-espagne .badge-derogation .anomalie {
  color: crimson;
}
