.derogation-assurance-espagne {
  padding: 0 1rem;
}

.derogation-assurance-espagne .decision-non-autorisee {
  margin-top: 2rem;
}

.derogation-assurance-espagne .prise-decision {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.derogation-assurance-espagne textarea {
  width: 99%;
  margin: 0 auto 0.5rem auto;
}

.derogation-assurance-espagne .les-boutons {
  display: flex;
  column-gap: 1rem;
  justify-content: center;
}
