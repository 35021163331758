.derogations-de-vr-par-projet {
    padding: 0 1rem;
}

.derogations-de-vr-par-projet .erreurs {
    margin-top: 1rem;
}

.derogations-de-vr-par-projet .chargement-en-cours {
    margin-top: 1rem;
}

.derogations-de-vr-par-projet .decision-non-autorisee {
    margin-top: 1rem;
}

.derogations-de-vr-par-projet .une-derogation {
    margin-bottom: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .titre-accordeon {
    display: flex;
    align-items: center;
}

.derogations-de-vr-par-projet .une-derogation .titre-accordeon > div {
    margin-right: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .une-derogation-contenu-accordeon {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

/* RESUME */
.derogations-de-vr-par-projet .une-derogation .resume {
    margin-bottom: 0.8rem;
}

.derogations-de-vr-par-projet .une-derogation .resume .materiel-non-reference {
    margin-bottom: 0.7rem;
}

.derogations-de-vr-par-projet .une-derogation .resume ul {
    margin: 0;
    padding: 0;
}

/* DECISION */
.derogations-de-vr-par-projet .une-derogation .decision {
    margin-bottom: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .decision ul {
    margin: 0;
    padding: 0;
}

.derogations-de-vr-par-projet .une-derogation .decision .details-decision {
    margin-left: 2rem;
}

/* PIECES-JOINTES */
.derogations-de-vr-par-projet .une-derogation .pieces-jointes {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.derogations-de-vr-par-projet .une-derogation .pieces-jointes .fichiers {
    display: flex;
    margin-left: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .pieces-jointes .fichiers > div {
    margin-right: .5rem;
}

/* FORMULAIRE AVIS BROKER */
.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .la-vr,
.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .la-piece-jointe,
.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .le-commentaire {
    margin-bottom: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker fieldset {
    padding-top: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr .tag {
    margin-right: .3rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr:last-child {
    margin-bottom: 0;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr div {
    flex: 1;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .la-piece-jointe input {
    margin-top: 1rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .le-commentaire {
    display: flex;
    flex-direction: column;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .le-commentaire textarea {
    flex: 1;
    margin-top: .3rem;
}

.derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .le-bouton {
    display: flex;
    justify-content: center;
}
