@media only screen and (max-width: 800px) {
    .derogations-de-vr-par-projet .une-derogation {
        line-height: 1.15rem;
    }

    .derogations-de-vr-par-projet .une-derogation .titre-accordeon {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;
    }

    .derogations-de-vr-par-projet .une-derogation .rc-collapse-content {
        padding-left: .9rem;
        padding-right: .9rem;
    }

    .derogations-de-vr-par-projet .une-derogation .titre-accordeon > div:first-child {
        margin-bottom: .3rem;
    }

    .derogations-de-vr-par-projet .une-derogation .une-derogation-contenu-accordeon {
        padding-left: 0;
        padding-right: 0;
    }

    /* RESUME */
    .derogations-de-vr-par-projet .une-derogation .resume ul {
        list-style-type: none;
    }

    /* DECISION */
    .derogations-de-vr-par-projet .une-derogation .decision ul {
        list-style-type: none;
    }

    /* PIECES-JOINTES */
    .derogations-de-vr-par-projet .une-derogation .pieces-jointes {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .derogations-de-vr-par-projet .une-derogation .pieces-jointes .fichiers {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .derogations-de-vr-par-projet .une-derogation .pieces-jointes .fichiers > div {
        margin-top: .3rem;
    }

    /* FORMULAIRE */
    .derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr {
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
    }

    .derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr div {
        flex: 0.5;
    }

    .derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .un-choix-de-vr .label-du-choix {
        flex: 2
    }

    .derogations-de-vr-par-projet .une-derogation .formulaire-avis-broker .vr-manuelle .label-du-choix {
        min-width: 100%;
        margin-bottom: .3rem;
    }
}
