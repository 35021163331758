.tab-list {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin: 0;
  background-color: white;
}

.tab-list li {
  display: flex;
  flex: 1;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border-right: 1px solid #ccc;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 0 1px 0 1px;
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: bold;
}
