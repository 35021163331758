@media only screen and (max-width: 800px) {
  .chaine-de-loyers .chiffres {
    font-size: 1rem;
  }

  .chaine-de-loyers .chiffres div:nth-child(2) span {
    font-size: 1rem;
    padding-left: 4px;
    padding-right: 8px;
  }
}
