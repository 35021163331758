.actions-sur-produits {
  display: flex;
  flex-direction: column;
}

.actions-sur-produits > div {
  margin-bottom: 10px;
}

.actions-sur-produits > div > button {
  width: 100%;
}
