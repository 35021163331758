@media only screen and (max-width: 800px) {
  .commissionnement {
    margin-bottom: 20px;
  }

  .commissionnement .carte {
    flex-grow: 1;
    margin-bottom: 8px;
  }

  .commissionnement .les-commissions > div {
    flex-grow: 1;
  }

  .commission-contenu {
    flex-grow: 1;
  }

  .commission-contenu textarea {
    flex-grow: 1;
  }

  .commissionnement .ajouter-une-commission {
    display: flex;
    align-self: flex-start;
  }

  .commissionnement .ajouter-une-commission button {
    flex-grow: 1;
  }
}
