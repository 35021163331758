.pcssc-alert .alert {
  padding: 10px;
}

.pcssc-alert .alert.button-secondary a {
  color: #fff;
  font-weight: bold;
}

.pcssc-alert .close-alert {
  height: 100%;
}

.pcssc-alert .close-alert span {
  background-color: #ffffff;
  padding: 0 4px;
  border-radius: 2px;
  color: #000000;
}
