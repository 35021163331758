@media only screen and (max-width: 800px) {
  .calc-header {
    flex-direction: column;
  }
  .calc-header .header-sub-title {
    font-size: 0.92rem;
    margin-bottom: 4px;
  }
  .calc-header .header-sub-text {
    font-size: 1rem;
  }
  .calc-header .header-div {
    padding: 4px 6px 4px 4px;
  }
  .calc-header .blocs-1,
  .calc-header .blocs-2 {
    flex: 1;
    min-height: 40px;
  }
  .calc-header .blocs-1 {
    border-bottom: 1px solid #dbdbdb;
  }
  #header-bloc-loyers {
    flex: 1;
    min-width: 0;
    border-left: none;
  }
  #header-bloc-loyers .header-sub-details {
    font-size: 0.5rem;
  }
  .header-franchise-trois-mois {
    font-size: 0.85rem;
  }
  #header-bloc-commission-commerciale {
    min-width: 0;
    flex: 1;
  }
  #header-bloc-depenses {
    min-width: 0;
    flex: 1;
    border-left: none;
  }
  #header-bloc-duree {
    min-width: 0;
    flex: 1;
  }
  #header-bloc-marge {
    min-width: 0;
    flex: 1;
  }
  #header-bloc-non-valeur {
    min-width: 0;
    flex: 1;
  }

  #header-bloc-duree .subtitle {
    font-size: 1rem;
    margin-top: -3px;
    text-transform: lowercase;
  }
}
