@media only screen and (max-width: 800px) {
  .bornes-de-vr #infobulle-bornes-de-vr {
    left: 0 !important;
    margin-left: 0.3rem;
    margin-right: 1rem;
  }

  .bornes-de-vr .__react_component_tooltip.type-info.place-right:after {
    border-right-color: transparent;
  }
}
