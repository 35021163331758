.app {
  padding-bottom: 20px;
}

.app,
.tiroir {
  color: darkslategrey;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-dir-col {
  display: flex;
  flex-direction: column;
}

.flex-dir-row {
  display: flex;
  flex-direction: row;
}

.flex-content-sb {
  display: flex;
  justify-content: space-between;
}

.flex-content-center {
  display: flex;
  justify-content: center;
}

.flex-ai-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.flex-3 {
  display: flex;
  flex: 3;
}

.sticky {
  z-index: 2;
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
}

.w100 {
  width: 100%;
}

input[type="text"],
input[type="number"],
textarea {
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 3px;
}

textarea[readonly] {
  background-color: #eee;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-red {
  color: red;
}

.text-bold {
  font-weight: bold;
}

.pointable {
  cursor: pointer;
}

.mha {
  margin-left: auto;
  margin-right: auto;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 50px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-0 {
  margin-top: 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.p-2-0 {
  padding: 2px 0;
}

.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.px-10 {
  padding: 0 10px;
}
.pl-20 {
  padding-left: 20px;
}
.px-20 {
  padding: 0 20px;
}

.w-115 {
  width: 115px;
}
.w-205 {
  width: 205px;
}
.w-350 {
  width: 350px;
}
.h-40 {
  height: 40px;
}
.cinquante-pourcent {
  width: 50%;
}

.qui-apparait {
  animation-name: apparait;
  animation-duration: 0.3s;
  animation-timing-function: linear;
}
@keyframes apparait {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bordered {
  border: 1px solid;
}

input.no-frame-border,
div.no-frame-border {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
}

input.no-bg,
div.no-bg {
  background: transparent !important;
}

.tag {
  padding: 5px;
  border-radius: 6px;
  display: inline-block;
  background-color: buttonface;
  font-size: 0.85rem;
}

.tags-triangles > span {
  font-size: 0.69rem;
  line-height: 0.69rem;
  padding: 5px;
  border-radius: 6px;
  display: inline-block;
  background-color: #f3f3f3;
}

.tags-triangles > span:first-child {
  position: relative;
  background-color: #e6e6e6;
  padding-left: 9px;
}

.tags-triangles > span:last-child {
  padding-left: 20px;
  padding-right: 8px;
}

.tags-triangles > span:first-child::after {
  width: 0;
  height: 0;
  border-top: 9px solid #f3f3f3;
  border-bottom: 10px solid #f3f3f3;
  border-left: 10px solid #e6e6e6;
  background-color: #e6e6e6;
  content: "";
  position: absolute;
  right: -6px;
  top: 0px;
}

.pure-button-primary {
  background-color: #0073bd;
}

/* purecss */
.button-success,
.button-error,
.button-warning,
.button-secondary {
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
  background: rgb(28, 184, 65);
}

.button-error {
  background: rgb(222, 60, 60);
}

.button-warning {
  background: rgb(223, 117, 20);
}

.button-secondary {
  background: rgb(66, 184, 221);
}

.button-xsmall {
  font-size: 70%;
}

.button-small {
  font-size: 85%;
}

.button-large {
  font-size: 110%;
}

.button-xlarge {
  font-size: 125%;
}

.input-xs {
  width: 24px;
}

.input-s {
  width: 72px;
}

.input-m {
  width: 110px;
}

.input-l {
  width: 130px;
}

@media screen and (min-width: 35.5em) {
  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1-2 {
    margin-left: 50%;
    *margin-left: 49.995%;
  }

  .offset-sm-1-3 {
    margin-left: 33.33%;
    *margin-left: 33.325%;
  }

  .offset-sm-2-3 {
    margin-left: 66.67%;
    *margin-left: 66.665%;
  }

  .offset-sm-1-4 {
    margin-left: 25%;
    *margin-left: 24.995%;
  }

  .offset-sm-3-4 {
    margin-left: 75%;
    *margin-left: 74.995%;
  }

  .offset-sm-1-5 {
    margin-left: 20%;
    *margin-left: 19.995%;
  }

  .offset-sm-2-5 {
    margin-left: 40%;
    *margin-left: 39.995%;
  }

  .offset-sm-3-5 {
    margin-left: 60%;
    *margin-left: 59.995%;
  }

  .offset-sm-4-5 {
    margin-left: 80%;
    *margin-left: 79.995%;
  }

  .offset-sm-1-6 {
    margin-left: 16.66%;
    *margin-left: 16.655%;
  }

  .offset-sm-5-6 {
    margin-left: 83.33%;
    *margin-left: 83.325%;
  }

  .offset-sm-1-8 {
    margin-left: 12.5%;
    *margin-left: 12.495%;
  }

  .offset-sm-3-8 {
    margin-left: 37.5%;
    *margin-left: 37.495%;
  }

  .offset-sm-5-8 {
    margin-left: 62.5%;
    *margin-left: 62.495%;
  }

  .offset-sm-7-8 {
    margin-left: 87.5%;
    *margin-left: 87.495%;
  }

  .offset-sm-1-12 {
    margin-left: 8.33%;
    *margin-left: 8.325%;
  }

  .offset-sm-5-12 {
    margin-left: 41.67%;
    *margin-left: 41.665%;
  }

  .offset-sm-7-12 {
    margin-left: 58.33%;
    *margin-left: 58.325%;
  }

  .offset-sm-11-12 {
    margin-left: 91.67%;
    *margin-left: 91.665%;
  }

  .offset-sm-1-24 {
    margin-left: 4.17%;
    *margin-left: 4.165%;
  }

  .offset-sm-5-24 {
    margin-left: 20.83%;
    *margin-left: 20.825%;
  }

  .offset-sm-7-24 {
    margin-left: 29.17%;
    *margin-left: 29.165%;
  }

  .offset-sm-11-24 {
    margin-left: 45.83%;
    *margin-left: 45.825%;
  }

  .offset-sm-13-24 {
    margin-left: 54.17%;
    *margin-left: 54.165%;
  }

  .offset-sm-17-24 {
    margin-left: 70.83%;
    *margin-left: 70.825%;
  }

  .offset-sm-19-24 {
    margin-left: 79.17%;
    *margin-left: 79.165%;
  }

  .offset-sm-23-24 {
    margin-left: 95.83%;
    *margin-left: 95.825%;
  }
}

@media screen and (min-width: 48em) {
  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1-2 {
    margin-left: 50%;
    *margin-left: 49.995%;
  }

  .offset-md-1-3 {
    margin-left: 33.33%;
    *margin-left: 33.325%;
  }

  .offset-md-2-3 {
    margin-left: 66.67%;
    *margin-left: 66.665%;
  }

  .offset-md-1-4 {
    margin-left: 25%;
    *margin-left: 24.995%;
  }

  .offset-md-3-4 {
    margin-left: 75%;
    *margin-left: 74.995%;
  }

  .offset-md-1-5 {
    margin-left: 20%;
    *margin-left: 19.995%;
  }

  .offset-md-2-5 {
    margin-left: 40%;
    *margin-left: 39.995%;
  }

  .offset-md-3-5 {
    margin-left: 60%;
    *margin-left: 59.995%;
  }

  .offset-md-4-5 {
    margin-left: 80%;
    *margin-left: 79.995%;
  }

  .offset-md-1-6 {
    margin-left: 16.66%;
    *margin-left: 16.655%;
  }

  .offset-md-5-6 {
    margin-left: 83.33%;
    *margin-left: 83.325%;
  }

  .offset-md-1-8 {
    margin-left: 12.5%;
    *margin-left: 12.495%;
  }

  .offset-md-3-8 {
    margin-left: 37.5%;
    *margin-left: 37.495%;
  }

  .offset-md-5-8 {
    margin-left: 62.5%;
    *margin-left: 62.495%;
  }

  .offset-md-7-8 {
    margin-left: 87.5%;
    *margin-left: 87.495%;
  }

  .offset-md-1-12 {
    margin-left: 8.33%;
    *margin-left: 8.325%;
  }

  .offset-md-5-12 {
    margin-left: 41.67%;
    *margin-left: 41.665%;
  }

  .offset-md-7-12 {
    margin-left: 58.33%;
    *margin-left: 58.325%;
  }

  .offset-md-11-12 {
    margin-left: 91.67%;
    *margin-left: 91.665%;
  }

  .offset-md-1-24 {
    margin-left: 4.17%;
    *margin-left: 4.165%;
  }

  .offset-md-5-24 {
    margin-left: 20.83%;
    *margin-left: 20.825%;
  }

  .offset-md-7-24 {
    margin-left: 29.17%;
    *margin-left: 29.165%;
  }

  .offset-md-11-24 {
    margin-left: 45.83%;
    *margin-left: 45.825%;
  }

  .offset-md-13-24 {
    margin-left: 54.17%;
    *margin-left: 54.165%;
  }

  .offset-md-17-24 {
    margin-left: 70.83%;
    *margin-left: 70.825%;
  }

  .offset-md-19-24 {
    margin-left: 79.17%;
    *margin-left: 79.165%;
  }

  .offset-md-23-24 {
    margin-left: 95.83%;
    *margin-left: 95.825%;
  }
}

@media screen and (min-width: 64em) {
  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1-2 {
    margin-left: 50%;
    *margin-left: 49.995%;
  }

  .offset-lg-1-3 {
    margin-left: 33.33%;
    *margin-left: 33.325%;
  }

  .offset-lg-2-3 {
    margin-left: 66.67%;
    *margin-left: 66.665%;
  }

  .offset-lg-1-4 {
    margin-left: 25%;
    *margin-left: 24.995%;
  }

  .offset-lg-3-4 {
    margin-left: 75%;
    *margin-left: 74.995%;
  }

  .offset-lg-1-5 {
    margin-left: 20%;
    *margin-left: 19.995%;
  }

  .offset-lg-2-5 {
    margin-left: 40%;
    *margin-left: 39.995%;
  }

  .offset-lg-3-5 {
    margin-left: 60%;
    *margin-left: 59.995%;
  }

  .offset-lg-4-5 {
    margin-left: 80%;
    *margin-left: 79.995%;
  }

  .offset-lg-1-6 {
    margin-left: 16.66%;
    *margin-left: 16.655%;
  }

  .offset-lg-5-6 {
    margin-left: 83.33%;
    *margin-left: 83.325%;
  }

  .offset-lg-1-8 {
    margin-left: 12.5%;
    *margin-left: 12.495%;
  }

  .offset-lg-3-8 {
    margin-left: 37.5%;
    *margin-left: 37.495%;
  }

  .offset-lg-5-8 {
    margin-left: 62.5%;
    *margin-left: 62.495%;
  }

  .offset-lg-7-8 {
    margin-left: 87.5%;
    *margin-left: 87.495%;
  }

  .offset-lg-1-12 {
    margin-left: 8.33%;
    *margin-left: 8.325%;
  }

  .offset-lg-5-12 {
    margin-left: 41.67%;
    *margin-left: 41.665%;
  }

  .offset-lg-7-12 {
    margin-left: 58.33%;
    *margin-left: 58.325%;
  }

  .offset-lg-11-12 {
    margin-left: 91.67%;
    *margin-left: 91.665%;
  }

  .offset-lg-1-24 {
    margin-left: 4.17%;
    *margin-left: 4.165%;
  }

  .offset-lg-5-24 {
    margin-left: 20.83%;
    *margin-left: 20.825%;
  }

  .offset-lg-7-24 {
    margin-left: 29.17%;
    *margin-left: 29.165%;
  }

  .offset-lg-11-24 {
    margin-left: 45.83%;
    *margin-left: 45.825%;
  }

  .offset-lg-13-24 {
    margin-left: 54.17%;
    *margin-left: 54.165%;
  }

  .offset-lg-17-24 {
    margin-left: 70.83%;
    *margin-left: 70.825%;
  }

  .offset-lg-19-24 {
    margin-left: 79.17%;
    *margin-left: 79.165%;
  }

  .offset-lg-23-24 {
    margin-left: 95.83%;
    *margin-left: 95.825%;
  }
}

@media screen and (min-width: 80em) {
  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1-2 {
    margin-left: 50%;
    *margin-left: 49.995%;
  }

  .offset-xl-1-3 {
    margin-left: 33.33%;
    *margin-left: 33.325%;
  }

  .offset-xl-2-3 {
    margin-left: 66.67%;
    *margin-left: 66.665%;
  }

  .offset-xl-1-4 {
    margin-left: 25%;
    *margin-left: 24.995%;
  }

  .offset-xl-3-4 {
    margin-left: 75%;
    *margin-left: 74.995%;
  }

  .offset-xl-1-5 {
    margin-left: 20%;
    *margin-left: 19.995%;
  }

  .offset-xl-2-5 {
    margin-left: 40%;
    *margin-left: 39.995%;
  }

  .offset-xl-3-5 {
    margin-left: 60%;
    *margin-left: 59.995%;
  }

  .offset-xl-4-5 {
    margin-left: 80%;
    *margin-left: 79.995%;
  }

  .offset-xl-1-6 {
    margin-left: 16.66%;
    *margin-left: 16.655%;
  }

  .offset-xl-5-6 {
    margin-left: 83.33%;
    *margin-left: 83.325%;
  }

  .offset-xl-1-8 {
    margin-left: 12.5%;
    *margin-left: 12.495%;
  }

  .offset-xl-3-8 {
    margin-left: 37.5%;
    *margin-left: 37.495%;
  }

  .offset-xl-5-8 {
    margin-left: 62.5%;
    *margin-left: 62.495%;
  }

  .offset-xl-7-8 {
    margin-left: 87.5%;
    *margin-left: 87.495%;
  }

  .offset-xl-1-12 {
    margin-left: 8.33%;
    *margin-left: 8.325%;
  }

  .offset-xl-5-12 {
    margin-left: 41.67%;
    *margin-left: 41.665%;
  }

  .offset-xl-7-12 {
    margin-left: 58.33%;
    *margin-left: 58.325%;
  }

  .offset-xl-11-12 {
    margin-left: 91.67%;
    *margin-left: 91.665%;
  }

  .offset-xl-1-24 {
    margin-left: 4.17%;
    *margin-left: 4.165%;
  }

  .offset-xl-5-24 {
    margin-left: 20.83%;
    *margin-left: 20.825%;
  }

  .offset-xl-7-24 {
    margin-left: 29.17%;
    *margin-left: 29.165%;
  }

  .offset-xl-11-24 {
    margin-left: 45.83%;
    *margin-left: 45.825%;
  }

  .offset-xl-13-24 {
    margin-left: 54.17%;
    *margin-left: 54.165%;
  }

  .offset-xl-17-24 {
    margin-left: 70.83%;
    *margin-left: 70.825%;
  }

  .offset-xl-19-24 {
    margin-left: 79.17%;
    *margin-left: 79.165%;
  }

  .offset-xl-23-24 {
    margin-left: 95.83%;
    *margin-left: 95.825%;
  }
}

.offset-1-2 {
  margin-left: 50%;
  *margin-left: 49.995%;
}

.offset-1-3 {
  margin-left: 33.33%;
  *margin-left: 33.325%;
}

.offset-2-3 {
  margin-left: 66.67%;
  *margin-left: 66.665%;
}

.offset-1-4 {
  margin-left: 25%;
  *margin-left: 24.995%;
}

.offset-3-4 {
  margin-left: 75%;
  *margin-left: 74.995%;
}

.offset-1-5 {
  margin-left: 20%;
  *margin-left: 19.995%;
}

.offset-2-5 {
  margin-left: 40%;
  *margin-left: 39.995%;
}

.offset-3-5 {
  margin-left: 60%;
  *margin-left: 59.995%;
}

.offset-4-5 {
  margin-left: 80%;
  *margin-left: 79.995%;
}

.offset-1-6 {
  margin-left: 16.66%;
  *margin-left: 16.655%;
}

.offset-5-6 {
  margin-left: 83.33%;
  *margin-left: 83.325%;
}

.offset-1-8 {
  margin-left: 12.5%;
  *margin-left: 12.495%;
}

.offset-3-8 {
  margin-left: 37.5%;
  *margin-left: 37.495%;
}

.offset-5-8 {
  margin-left: 62.5%;
  *margin-left: 62.495%;
}

.offset-7-8 {
  margin-left: 87.5%;
  *margin-left: 87.495%;
}

.offset-1-12 {
  margin-left: 8.33%;
  *margin-left: 8.325%;
}

.offset-5-12 {
  margin-left: 41.67%;
  *margin-left: 41.665%;
}

.offset-7-12 {
  margin-left: 58.33%;
  *margin-left: 58.325%;
}

.offset-11-12 {
  margin-left: 91.67%;
  *margin-left: 91.665%;
}

.offset-1-24 {
  margin-left: 4.17%;
  *margin-left: 4.165%;
}

.offset-5-24 {
  margin-left: 20.83%;
  *margin-left: 20.825%;
}

.offset-7-24 {
  margin-left: 29.17%;
  *margin-left: 29.165%;
}

.offset-11-24 {
  margin-left: 45.83%;
  *margin-left: 45.825%;
}

.offset-13-24 {
  margin-left: 54.17%;
  *margin-left: 54.165%;
}

.offset-17-24 {
  margin-left: 70.83%;
  *margin-left: 70.825%;
}

.offset-19-24 {
  margin-left: 79.17%;
  *margin-left: 79.165%;
}

.offset-23-24 {
  margin-left: 95.83%;
  *margin-left: 95.825%;
}

/* purecss-components */
.pcssc-invisible {
  display: none;
}

/* React-drawer */
/* Pas stylable via le composant, donc on ajoute du CSS ici */
.react-drawer-drawer {
  z-index: 10 !important;
  background-color: white !important;
  height: auto !important;
  max-height: 95vh !important;
  overflow-y: scroll;
  padding-bottom: 15px;
}
.react-drawer-overlay {
  z-index: 9;
}

/* rc-collapse */
.rc-collapse-header-custom {
  line-height: normal !important;
}

/* react-autosuggest */
/* https://codepen.io/moroshko/pen/LGNJMy */
.react-autosuggest__container {
  position: relative;
  display: flex;
}
.react-autosuggest__input {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 3px;
  flex: 1;
  margin-left: 0;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 27px;
  margin-left: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 12px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #deebff;
}
