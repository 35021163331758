@media only screen and (max-width: 800px) {
  .creation-de-ft-contenu {
    flex-wrap: wrap;
  }

  .creation-de-ft-contenu .actions {
    flex: 1;
    margin-top: 1rem;
  }
}
