@media only screen and (max-width: 800px) {
  .carte > header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  .carte > header > div {
    padding: 8px;
  }

  .carte > header .carte-icone-accordeon {
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 0;
  }

  .carte > header .carte-icone-accordeon-contenu-masque > svg {
    transition: transform 0.2s ease-out;
    transform: rotate(180deg);
  }

  .carte > header .carte-icone-accordeon-contenu-visible > svg {
    transition: transform 0.2s ease-out;
  }

  .carte .carte-contenu {
    padding: 10px 10px;
  }

  /*
  Le CSS lié au masquage de contenu (les cartes "accordéon") est uniquement dans le CSS mobile.
  Comme ça, pas d'accordéon en version non mobile.
  */
  .carte .carte-contenu-visible {
    display: flex;
  }
  .carte .carte-contenu-masque {
    display: none;
  }
}
