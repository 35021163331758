.derogation-de-marge {
  padding: 0 1rem;
}

.derogation-de-marge .le-commentaire {
  margin-top: 1rem;
}

.derogation-de-marge .les-boutons {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.derogation-de-marge .les-boutons button {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.derogation-de-marge .recalcul-de-marge h4 {
  margin-bottom: 0.4rem;
}

.derogation-de-marge .recalcul-de-marge > div {
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
}
.derogation-de-marge .recalcul-de-marge label {
  margin-right: 1rem;
}
.derogation-de-marge .recalcul-de-marge .marge-recalculee {
  margin-left: 1rem;
}
