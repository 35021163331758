.derogations-desktop {
  padding: 1rem 0;
}

.derogations-desktop .tab-list li {
  font-size: 1.2rem;
}

.derogations-desktop .ReactTable {
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.ReactTable .rt-tbody .rt-tr:hover {
  cursor: pointer;
  background-color: #eee;
}

.derogations-desktop .ReactTable .vieille-derogation {
  background-color: rgba(222, 195, 21, 0.1) !important;
}
