.nature-du-calcul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nature-du-calcul h3 {
  margin: 0;
}

.nature-du-calcul input[type="radio"] {
  margin-left: 1rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

.nature-du-calcul label {
  cursor: pointer;
}
