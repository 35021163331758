@media only screen and (max-width: 800px) {
  .derogation-de-marge .recalcul-de-marge h4 {
    margin-top: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .derogation-de-marge .recalcul-de-marge > div {
    flex-wrap: wrap;
  }

  .derogation-de-marge .recalcul-de-marge .marge-recalculee {
    margin-left: 0;
    margin-top: 0.4rem;
  }
}
