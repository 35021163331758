.commissionnement .les-commissions {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.commissionnement .les-commissions > div {
  margin-left: 5px;
}

.commissionnement .carte {
  display: flex;
}

.commission-contenu {
  display: flex;
  flex-direction: column;
}

.commission-contenu > div {
  display: flex;
  margin-bottom: 5px;
}

.commission-contenu input {
  flex-grow: 1;
}

.commission-contenu .input-addon-append-item {
  display: flex;
  align-items: center;
}

.commission-contenu textarea {
  flex-grow: 1;
}
