.badge-erreur {
  border-radius: 5px;
  border: 2px solid crimson !important;
  background: #d9534f;
  padding: 15px;
  display: flex;
  margin: auto;
  word-break: break-word;
}

.badge-erreur .detail-erreur {
  margin: 0 0 0 20px;
  color: white;
}

.badge-erreur h3 {
  margin: 0 0 10px 0;
}

.badge-erreur a {
  color: white;
  font-weight: bold;
}
