.erreur {
  border: 1px solid crimson !important;
}

.input-addon-container {
  display: flex;
}

.input-addon-field {
  text-align: right;
}

.input-addon-field:disabled {
  background-color: #ebebe4;
}

.input-addon-append-item {
  border: 1px solid #cccccc;
  border-radius: 0 3px 3px 0;
  border-left: none;
  padding: 3px 5px;
  display: inline;
  text-align: center;
  background-color: buttonface;
}

.border-radius-append-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
