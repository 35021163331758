.marge {
  display: flex;
  align-items: center;
}

.marge .marge-montant,
.marge .marge-pourcentage {
  margin-left: 1rem;
}

.marge-recalculee-info {
  display: flex;
  margin-left: 0.6rem;
}

.marge-recalculee-info > div {
  margin-right: 0.4rem;
}

.marge-derogation {
  margin-top: 4px;
}
