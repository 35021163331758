.retour-accueil-derogations {
  margin-top: 1rem;
}

.retour-accueil-derogations a {
  color: white;
  text-decoration: none;
  display: flex;
  flex: 1;
}

.retour-accueil-derogations a .pure-button {
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
