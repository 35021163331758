.calc-header {
  display: flex;
  min-height: 100px;
  background-color: #f9f9f9;
}

.calc-header.anomalies {
  min-height: 0;
  color: #9f6000;
  background-color: #feefb3;
  padding: 1rem;
  font-weight: bold;
  flex-direction: column;
}

.calc-header.anomalies ul {
  padding: 0 0 0 1rem;
  margin: 0;
}

.calc-header .blocs-1,
.calc-header .blocs-2 {
  display: flex;
  flex: 1;
}

.calc-header .header-sub-title {
  color: #234459;
  font-size: 1.3rem;
  margin-bottom: 2px;
}

.calc-header .header-sub-text {
  font-size: 2.15rem;
  font-weight: bold;
  color: #0073bd;
}

.calc-header .header-sub-details {
  font-size: 1rem;
  font-weight: normal;
}

.calc-header .header-div {
  padding: 10px;
  flex-grow: 1;
}

.calc-header .header-div-avertissement {
  color: #9f6000;
  background-color: #feefb3;
}

.calc-header .header-div-avertissement .header-sub-title {
  color: #9f6000;
}

.calc-header .header-div {
  border-left: 1px solid #dbdbdb;
}

.header-franchise-trois-mois {
  text-align: right;
  font-style: italic;
  font-size: 1.15rem;
  margin-top: 5px;
}

#header-bloc-loyers {
  min-width: 200px;
}
#header-bloc-loyers .chaine-de-loyers {
  margin-top: 5px;
}

#header-bloc-commission-commerciale,
#header-bloc-non-valeur {
  min-width: 155px;
}

#header-bloc-marge {
  min-width: 125px;
}

#header-bloc-duree {
  min-width: 70px;
}

#header-bloc-depenses {
  min-width: 200px;
}

#header-bloc-duree .subtitle {
  font-size: 1.62rem;
  margin-top: -5px;
  text-transform: lowercase;
}
