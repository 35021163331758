@media only screen and (max-width: 800px) {
  .maintenance h3 {
    margin-bottom: 10px !important;
  }
  .maintenance-champs {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
  }
}
