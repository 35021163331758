.creation-de-ft {
  transition: background-color 0.3s;
  background-color: #f3f3f3;
  border-bottom: 1px solid;
  min-height: 45px;
  padding: 1rem;
}

.creation-de-ft.anomalies-bloquantes {
  color: #9f6000;
  background-color: #feefb3;
}

.creation-de-ft .creation-de-fiche-sub-title {
  font-size: 0.85rem;
}

.creation-de-ft .creation-de-fiche-sub-text {
  font-weight: bold;
  margin-left: 1rem;
}

.creation-de-ft.anomalies-bloquantes .creation-de-fiche-sub-title {
  color: #ffffff;
}

.creation-de-ft.anomalies-bloquantes .creation-de-fiche-sub-title {
  color: #9f6000;
}

.creation-de-ft form button[disabled]:hover {
  cursor: not-allowed;
}

.creation-de-ft .confirmation ul {
  margin: 0;
}
.creation-de-ft .confirmation ul li {
  margin-bottom: 17px;
}
.creation-de-ft .confirmation ul li textarea {
  margin-top: 7px;
  margin-bottom: 0;
}
.creation-de-ft .confirmation ul li p {
  margin-top: 7px;
}

.creation-de-ft-contenu {
  display: flex;
  justify-content: space-between;
}

.creation-de-ft-contenu .actions button {
  width: 100%;
}
