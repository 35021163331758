.produits-et-actions {
  display: flex;
  flex-wrap: wrap;
}

.produits-liste {
  display: flex;
  flex-wrap: wrap;
}

.un-produit {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
}

.un-produit .carte header {
  color: #0073bd;
  background-color: #f9f9f9;
}

.un-produit .ligne-de-formulaire {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}

.un-produit .ligne-de-formulaire label {
  cursor: pointer;
}

.un-produit .ligne-de-formulaire input,
.un-produit .text-bold {
  margin-left: 5px;
}

.un-produit .assurance-france .souscription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.08rem;
  margin-top: 0.4rem;
}

.un-produit .assurance-france .souscription .titre {
  margin-right: 1rem;
  max-width: 115px;
}

.un-produit .bouton-supprimer-produit {
  margin-top: 30px;
}
