.erreur-overlay {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.65;
  z-index: 100;
}

.revenir-etat-stable {
  position: absolute;
  z-index: 200;
  left: 50%;
  top: 50px;
  width: 80%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.revenir-etat-stable button {
  font-size: 1.38rem;
  text-transform: uppercase;
  margin: 30px auto 0 auto;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}
