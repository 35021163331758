.instantanes .prendre-instantane {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.instantanes .prendre-instantane:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.instantanes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.un-instantane {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 15px;
}

.un-instantane > div {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #cccccc;
}

.un-instantane > div:hover {
  background-color: aliceblue;
}

.un-instantane p {
  margin: 0;
}
.un-instantane .timestamp {
  font-size: 0.77rem;
  margin-top: auto;
  padding-top: 6px;
}

.un-instantane .supprimer-instantane {
  border-radius: 0 0 5px 5px;
  border-top: none;
  text-align: center;
  padding: 3px;
  font-size: 0.92rem;
}

.un-instantane .supprimer-instantane:hover {
  background-color: darkorange;
  color: white;
}

.un-instantane .details {
  position: relative;
}

.un-instantane .details .overlay-blocage {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.un-instantane:hover .details .overlay-blocage {
  display: flex;
  background-color: aliceblue;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
}
