@media only screen and (max-width: 800px) {
  .calculateur {
    flex-wrap: wrap;
  }
  .calculateur .calculateur-simulation {
    width: 100%;
  }
  .calculateur .calculateur-instantanes {
    width: auto;
    flex-grow: 1;
  }
  .calculateur .actions-en-un-clic {
    flex-wrap: wrap;
  }
  .calculateur .actions-en-un-clic > div {
    gap: 1.2rem;
  }
  .calculateur .paliers-souhaites-et-conditions {
    flex-wrap: wrap;
  }
  .calculateur .paliers-souhaites-et-conditions .conditions {
    margin-left: 0;
    margin-top: 20px;
  }
  .calculateur .parametres-generaux {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .calculateur .parametres-generaux .select-marque-itl-active {
    width: 100%;
    max-width: unset;
  }
}
