@media only screen and (max-width: 800px) {
  .assurance-espagne {
    margin-left: 0;
    margin-top: 0.4rem;
  }

  .assurance-espagne .main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .assurance-espagne .titre {
    line-height: initial;
    margin-bottom: 0.5rem;
  }

  .assurance-espagne .pure-button-group {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
  }

  .assurance-espagne button {
    flex: 1;
  }

  .assurance-espagne-saisie {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .assurance-espagne-saisie .une-prime {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .assurance-espagne .aide-a-la-saisie {
    width: auto;
  }

  .assurance-espagne .aide-a-la-saisie .badge-warning {
    display: flex;
    flex-direction: column;
  }

  .assurance-espagne .aide-a-la-saisie button {
    margin-top: 1rem;
    margin-left: 0;
    display: flex;
  }
}
