.derogations-mobile .spinner {
  margin-top: 1rem;
}

.derogations-mobile .tab-list {
  font-size: 0.85rem;
}

.derogations-mobile .une-derogation {
  border: 1px solid darkslategrey;
  padding: 0.3rem 0.4rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.derogations-mobile .une-derogation p {
  margin: 0;
}

.derogations-mobile .une-derogation button {
  display: flex;
  margin: auto;
}

.derogations-mobile .une-derogation .actions {
  display: flex;
}

.derogations-mobile .une-derogation .actions span {
  display: flex;
  margin: auto;
  font-size: 1rem;
  padding: 0.7rem;
  text-transform: capitalize;
}

.derogations-mobile .une-derogation .vieille-derogation {
  background-color: rgba(222, 195, 21, 0.1) !important;
}
