.teg {
  display: flex;
  align-items: center;
}

.teg input {
  margin-left: 1rem;
}

.teg .teg-retour-grille {
  cursor: pointer;
  margin-left: 0.5rem;
}

.teg .derogation-teg {
  margin-left: 1rem;
}

.teg .derogation-teg .tag {
  margin-right: 0.3rem;
}
